import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Label } from '../../../styles';
import { useTranslation } from 'react-i18next';
import { PromotionType } from '__generated__/globalTypes';
import { PromoTagsSelector } from '../../../components/promoTagsSelector';
import { PercentageSelector } from 'components/_dashboard/promotion/components/percentageSelector';
import { PromoDiscountAmount } from 'components/_dashboard/promotion/components/promoDiscountAmount';
import {
  DiscountType,
  useDiscountTypeSelector
} from 'components/_dashboard/promotion/components/useDiscountTypeSelector';
import { PromoFreeN } from 'components/_dashboard/promotion/components/promoFreeN';
import {
  createPromoValidator,
  getVoucherDetailsValidationList
} from 'components/_dashboard/promotion/tools';
import { PromoSelector } from 'components/_dashboard/promotion/components/PromoSelector';
import { CommonInput } from 'components/_dashboard/promotion/formik/commonInput';
import { renderContent, tabContent } from '../tabContent';
import { TagsIcons } from 'components/_dashboard/promotion/components/tagsIcons';
import { CommonCheckBox } from 'components/_dashboard/promotion/formik/commonCheckBox';
import { PromoQtyRestricted } from 'components/_dashboard/promotion/components/promoQtyRestricted';
import { useParams } from 'react-router';
import { IPromoProps } from '../../type';

export const VoucherDetails: React.FC<IPromoProps> = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;
  // get type from url params
  const { values } = formik;
  const { discountedItemQty, isPercentage, isLoyalty } = values;

  const [isExcludeTagsChecked, setIsExcludeTagsChecked] = useState(
    formik.values.excludedPromotionTags?.length
      ? formik.values.excludedPromotionTags?.length > 0
      : false
  );

  const [isUpToAmountChecked, setIsUpToAmountChecked] = useState(
    formik.values.upToAmount ? formik.values.upToAmount > 0 : false
  );

  const { discountTypeElement, discountType } = useDiscountTypeSelector({ formik });

  const handleExcludedTagsCheckBoxOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!checked) {
      formik.setFieldValue('excludedPromotionTags', []);
    }
    setIsExcludeTagsChecked(checked);
  };
  const handleUpToAmountCheckBoxOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!checked) {
      formik.setFieldValue('upToAmount', 0);
    }
    setIsUpToAmountChecked(checked);
  };

  const canMove = useCallback(async () => {
    // name is required
    // numberOfDeals is required and greater than 0
    let message = '';
    let canMove = true;
    try {
      const list = getVoucherDetailsValidationList(values);
      for (const path of list) {
        await setFieldTouchedNameFunc(path);
      }
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [values, setFieldTouchedNameFunc]);

  const wrappedCanMove = useCallback(() => canMove(), [canMove]);
  useEffect(() => {
    setCanMoveFunc(() => wrappedCanMove);
  }, [setCanMoveFunc, wrappedCanMove]);

  const loyaltyDetailsContents: tabContent[] = [
    {
      mdSize: 12,
      node: {
        node: (
          <CommonInput
            formik={formik}
            fieldPath={'loyaltyDetails.expiryDays'}
            type="number"
            endAdornment="Days"
            label="Expiry days"
            placeholder="Loyalty Progress expires in 30 days"
            TooltipText="expiryDays defines how long each individual order remains valid in the loyalty program. Once an order expires, it no longer contributes to the total count towards earning a voucher. For example, if an earlier order expires, only the remaining valid orders will count towards the voucher."
          />
        )
      },
      isHidden: !isLoyalty
    }
  ];

  const percentageContents: tabContent[] = [
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'minimumAmount'}
          type="text"
          endAdornment="AUD"
          label="Minimum Spend"
          placeholder="Spend $50 or more to get 10% off"
        />
      )
    },
    {
      node: <PromoTagsSelector formik={formik} fieldPath="includedPromotionTags" label="Of what?" />
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="includedPromotionTags" />
    },
    {
      mdSize: 12,
      node: <PercentageSelector formik={formik} isOffer={false} />,
      isHidden: isLoyalty
    },
    {
      node: <PromoDiscountAmount formik={formik} />,
      isHidden: isLoyalty
    },
    {
      node: (
        <PromoTagsSelector
          formik={formik}
          fieldPath="includedGetItemsPromoTags"
          label="Applicable Items"
        />
      ),
      isHidden: isLoyalty
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="includedGetItemsPromoTags" />
    },
    {
      node: (
        <FormControlLabel
          value={isExcludeTagsChecked}
          checked={isExcludeTagsChecked}
          onChange={handleExcludedTagsCheckBoxOnChange}
          control={<Checkbox />}
          label="Exclude items from promotion"
          labelPlacement="end"
        />
      ),
      isHidden: isLoyalty
    },
    {
      mdSize: 12,
      node: (
        <PromoTagsSelector
          formik={formik}
          fieldPath="excludedPromotionTags"
          label="Inapplicable Items"
        />
      ),
      isHidden: !isExcludeTagsChecked
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="excludedPromotionTags" />
    },
    ...loyaltyDetailsContents,
    {
      node: (
        <CommonCheckBox
          formik={formik}
          fieldPath="applyOptionDiscount"
          label="Apply discount to options"
        />
      ),
      isHidden: isLoyalty
    },
    {
      node: <CommonCheckBox formik={formik} fieldPath="isHiddenInApp" label="Hide in app" />
    },
    {
      node: (
        <CommonCheckBox
          formik={formik}
          value={isUpToAmountChecked}
          handleOnChange={handleUpToAmountCheckBoxOnChange}
          label="Limit total discount amount"
        />
      ),
      isHidden: !isPercentage || isLoyalty
    },
    {
      mdSize: 12,
      node: {
        node: (
          <CommonInput
            formik={formik}
            fieldPath={'upToAmount'}
            type="text"
            endAdornment="AUD"
            label="Up to Amount"
            placeholder="Up to $50"
          />
        )
      },
      isHidden: !isUpToAmountChecked
    }
  ];
  const spendContents: tabContent[] = [
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'minimumAmount'}
          type="text"
          endAdornment="AUD"
          label="Minimum Spend"
          placeholder="Spend $50 or more to get 10% off"
        />
      )
    },
    {
      node: <PromoTagsSelector formik={formik} fieldPath="includedPromotionTags" label="Of what?" />
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="includedPromotionTags" />
    },
    {
      node: (
        <CommonCheckBox
          formik={formik}
          value={isExcludeTagsChecked}
          handleOnChange={handleExcludedTagsCheckBoxOnChange}
          label="Exclude items from spend"
        />
      )
    },
    {
      mdSize: 12,
      node: {
        node: (
          <PromoTagsSelector
            formik={formik}
            fieldPath="excludedPromotionTags"
            label="Excluded Items"
          />
        )
      },
      isHidden: !isExcludeTagsChecked
    },
    {
      node: <TagsIcons formik={formik} fieldPath="excludedPromotionTags" />
    },
    {
      mdSize: 12,
      node: <Label>{t('Get Free Item')}</Label>
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'discountedItemQty'}
          disabled={discountedItemQty === 'Infinite'}
          type="text"
          label="Get how many?"
          placeholder="Buy 3 items"
        />
      )
    },
    {
      node: (
        <PromoTagsSelector formik={formik} fieldPath="includedGetItemsPromoTags" label="Of what?" />
      )
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="includedGetItemsPromoTags" />
    },
    ...loyaltyDetailsContents,
    {
      node: <CommonCheckBox formik={formik} fieldPath="isHiddenInApp" label="Hide in app" />
    }
  ];
  const buyNFreeNContents: tabContent[] = [
    {
      mdSize: 12,
      node: discountTypeElement
    },
    // {discountType === DiscountType.spend && (

    // )}
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'minimumAmount'}
          type="text"
          endAdornment="AUD"
          label="Minimum Spend"
          placeholder="Spend $50 or more to get 10% off"
        />
      ),
      isHidden: discountType === DiscountType.buyN
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'buyN'}
          type="number"
          label="Buy how many?"
          placeholder="Buy 3 items"
        />
      ),
      isHidden: discountType === DiscountType.spend
    },
    {
      node: <PromoTagsSelector formik={formik} fieldPath="includedPromotionTags" label="Of what?" />
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="includedPromotionTags" />
    },
    {
      mdSize: 12,
      node: (
        <CommonCheckBox
          formik={formik}
          value={isExcludeTagsChecked}
          handleOnChange={handleExcludedTagsCheckBoxOnChange}
          label="Exclude items from buy"
        />
      )
    },
    {
      mdSize: 12,
      node: {
        node: (
          <PromoTagsSelector
            formik={formik}
            fieldPath="excludedPromotionTags"
            label="Excluded Items"
          />
        )
      },
      isHidden: !isExcludeTagsChecked
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="excludedPromotionTags" />,
      isHidden: !isExcludeTagsChecked
    },
    {
      mdSize: 12,
      node: <PromoQtyRestricted formik={formik} label="Get Items" />,
      isHidden: isLoyalty
    },
    {
      node: <PromoFreeN formik={formik} />,
      isHidden: isLoyalty
    },
    {
      node: (
        <PromoTagsSelector formik={formik} fieldPath="includedGetItemsPromoTags" label="Of what?" />
      ),
      isHidden: isLoyalty
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="includedGetItemsPromoTags" />,
      isHidden: isLoyalty
    },
    {
      mdSize: 12,
      node: <PercentageSelector formik={formik} isOffer={false} label="Get Item Discount" />,
      isHidden: isLoyalty
    },
    {
      mdSize: 12,
      node: { node: <PromoDiscountAmount formik={formik} /> },
      isHidden: isLoyalty
    },
    ...loyaltyDetailsContents,
    {
      node: (
        <CommonCheckBox
          formik={formik}
          fieldPath="applyOptionDiscount"
          label="Apply discount to options"
        />
      ),
      isHidden: isLoyalty
    },
    {
      mdSize: isUpToAmountChecked ? 12 : 6,
      node: (
        <CommonCheckBox
          formik={formik}
          value={isUpToAmountChecked}
          handleOnChange={handleUpToAmountCheckBoxOnChange}
          label="Limit total discount amount"
        />
      ),
      isHidden: !isPercentage || isLoyalty
    },
    {
      mdSize: 12,
      node: {
        node: (
          <CommonInput
            formik={formik}
            fieldPath={'upToAmount'}
            type="text"
            endAdornment="AUD"
            label="Up to Amount"
            placeholder="Up to $50"
          />
        )
      },
      // isUpToAmountChecked == true and isPercentage == true => show
      isHidden: !isUpToAmountChecked || !isPercentage
    }
  ];

  const luckyDrawContents: tabContent[] = [
    {
      mdSize: 12,
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'prizeDescription'}
          type="text"
          label="Prize Description"
          placeholder="Please enter the description of the prize"
          multiline={true}
        />
      )
    },
    {
      mdSize: 12,
      node: {
        node: (
          <CommonInput
            formik={formik}
            fieldPath={'minimumAmount'}
            type="text"
            endAdornment="AUD"
            label="Minimum Spend"
            placeholder="Spend $50 or more to get 10% off"
            TooltipText="Minimum Spend required to enter the lucky draw"
          />
        )
      }
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'winCountLimitPerPerson'}
          type="number"
          label="Win Limit Per Customer"
          placeholder="Maximum Number of Wins Allowed Per User"
          TooltipText="Maximum Number of Wins Allowed Per User"
        />
      )
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'entryLimitPerUser'}
          type="number"
          label="Entry Limit Per Customer"
          placeholder="Maximum Number Of Entries Allowed Per User"
          TooltipText="Maximum Number Of Entries Allowed Per User"
        />
      )
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'winnerCount'}
          type="number"
          label="Global Winner Count"
          placeholder="Total Global Win Count For This Lucky Draw"
          TooltipText="Total Global Win Count For This Lucky Draw"
        />
      )
    },
    {
      node: (
        <PromoSelector
          formik={formik}
          promoValidator={createPromoValidator([PromotionType.luckyDraw])}
          label="Promotions"
        />
      )
    },
    {
      mdSize: 12,
      node: (
        <CommonCheckBox
          formik={formik}
          fieldPath="isEntryAutomatic"
          title="Automatic"
          label="Automatically enter into lucky draw"
        />
      )
    },
    {
      mdSize: 12,
      node: (
        <CommonCheckBox
          formik={formik}
          fieldPath="isPaymentRequired"
          title="Payment First"
          label="Entry requires pre-payment"
        />
      )
    }
  ];

  const loyaltyContents: tabContent[] = [
    {
      node: (
        <PromoTagsSelector
          formik={formik}
          fieldPath="includedPromotionTags"
          label="Required Items"
        />
      )
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="includedPromotionTags" />
    },
    {
      mdSize: 12,
      node: (
        <CommonCheckBox
          formik={formik}
          value={isExcludeTagsChecked}
          handleOnChange={handleExcludedTagsCheckBoxOnChange}
          label="Exclude items from promotion"
        />
      )
    },
    {
      node: (
        <PromoTagsSelector
          formik={formik}
          fieldPath="excludedPromotionTags"
          label="Inapplicable Items"
        />
      ),
      isHidden: !isExcludeTagsChecked
    },
    {
      mdSize: 12,
      node: <TagsIcons formik={formik} fieldPath="excludedPromotionTags" />
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'loyaltySettings.pointsPerDollar'}
          type="number"
          label="Points per dollar"
          endAdornment="points"
          placeholder="Earn 2 points for every $1 spent."
          TooltipText="Earn 2 points for every $1 spent."
        />
      )
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'loyaltySettings.maxPointsPerTransaction'}
          type="number"
          label="Max points per transaction"
          endAdornment="points"
          placeholder="Earn up to 500 points per order."
          TooltipText="Earn up to 500 points per order."
        />
      )
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'loyaltySettings.expiryDays'}
          type="number"
          label="Points expiry days"
          endAdornment="Days"
          placeholder="Points expire in 365 days."
          TooltipText="Points expire in 365 days."
        />
      )
    }
  ];
  const render = () => {
    switch (params.promoType) {
      case PromotionType.percentage:
        return renderContent(percentageContents);
      case PromotionType.spend:
        return renderContent(spendContents);
      case PromotionType.buynGetFree:
        return renderContent(buyNFreeNContents);
      case PromotionType.luckyDraw:
        return renderContent(luckyDrawContents);
      case PromotionType.loyalty:
        return renderContent(loyaltyContents);
      default:
        return null;
    }
  };
  return (
    <Grid sx={{ width: '100%' }} container spacing={1}>
      {render()}
    </Grid>
  );
};
