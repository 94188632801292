import { FormikProps, getIn } from 'formik';
import { FormControl, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';
import { Label } from '../styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { DeepKeyOf } from './type';

interface commonInputProps<T> {
  formik: FormikProps<T>;
  fieldPath: DeepKeyOf<T>;
  type?: 'number' | 'text';
  disabled?: boolean;
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: CSSProperties;
  label?: string;
  TooltipText?: string;
  placeholder?: string;
  endAdornment?: string;
  multiline?: boolean;
}

export const CommonInput = <T,>(props: commonInputProps<T>) => {
  const {
    formik,
    fieldPath = '',
    type = 'text',
    handleOnChange,
    disabled = false,
    sx = {},
    placeholder = '',
    multiline = false,
    label,
    TooltipText,
    endAdornment
  } = props;
  const { touched, errors, getFieldProps } = formik;

  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };

  const getValue = () => {
    const value = getFieldProps(fieldPath).value;
    if (value !== undefined) {
      return value;
    }
    return '';
  };

  const handleValueOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (handleOnChange) {
      handleOnChange(e);
      return;
    }
    // if type is number, convert to number
    if (type === 'number') {
      // is NaN, return
      if (isNaN(Number(e.target.value))) {
        return;
      }
      formik.setFieldValue(fieldPath, Number(e.target.value));
      return;
    }
    // if type is text, set value as is
    formik.setFieldValue(fieldPath, e.target.value);
  };

  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        {label && (
          <Label>
            {t(label)}
            {TooltipText && (
              <Tooltip title={t(TooltipText)}>
                <IconButton>
                  <HelpOutlineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Label>
        )}
        <TextField
          disabled={disabled}
          multiline={multiline}
          placeholder={t(placeholder)}
          type={type}
          name={fieldPath}
          value={getValue()}
          InputProps={{
            endAdornment: endAdornment ? (
              <InputAdornment position="end">{t(endAdornment)}</InputAdornment>
            ) : null
          }}
          onChange={handleValueOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(getIn(touched, fieldPath) && getIn(errors, fieldPath))}
          helperText={getIn(touched, fieldPath) && getIn(errors, fieldPath)}
        />
      </FormControl>
    </>
  );
};
