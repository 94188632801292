import { visuallyHidden } from '@mui/utils';
import { Box, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

type PromotionListHeadProps<T> = {
  order: 'asc' | 'desc';
  orderBy: string;
  headLabel: any[];
  onRequestSort: (id: keyof T) => void;
  isOffer?: boolean;
};

export default function PromotionListHead<T>({
  order,
  orderBy,
  headLabel,
  onRequestSort,
  isOffer
}: PromotionListHeadProps<T>) {
  const { t } = useTranslation();
  const offerBackGroundColor = isOffer ? '#DCC179' : '#27A647';
  const noColumn = headLabel.filter((item: any) => {
    return item.id === 'actions' || item.id === '';
  })?.length;
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={{
              background: offerBackGroundColor,
              color: 'white',
              width:
                headCell.id !== 'actions' && headCell.id !== ''
                  ? `${(100 - noColumn * 10) / (headLabel.length - noColumn)}%`
                  : '10%'
            }}
            align={
              headCell.alignRight === undefined ? 'center' : headCell.alignRight ? 'right' : 'left'
            }
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={() => {
                if (headCell.id !== 'actions' && headCell.id !== '') {
                  onRequestSort(headCell.id);
                }
              }}
            >
              {t(`${headCell.label}`)}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
