import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import giftCardReducer from './slices/giftCard';
import faqReducer from './slices/faq';
import promotionReducer from './slices/promotion';
import merchantReducer from './slices/merchant';
import menuReducer from './slices/menu';
import orderReducer from './slices/order';
import bookingSessionsReducer from './slices/booking-sessions';
import bookingReducer from './slices/booking';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import authReducer from './slices/auth';
import loyaltyReducer from './slices/loyalty';
import promotionsManagementReducer from './slices/promotions-management';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-'
  // whitelist: ['sortBy', 'checkout']
};

const authPersistConfig = {
  key: 'auth',
  storage,
  keyPrefix: 'redux-'
  // whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  promotion: promotionReducer,
  loyalty: loyaltyReducer,
  merchant: merchantReducer,
  menu: menuReducer,
  order: orderReducer,
  bookingSessions: bookingSessionsReducer,
  promotionsManagement: promotionsManagementReducer,
  booking: bookingReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  giftCard: giftCardReducer,
  FAQ: faqReducer,
  product: persistReducer(productPersistConfig, productReducer),
  auth: persistReducer(authPersistConfig, authReducer)
});

export { rootPersistConfig, rootReducer };
