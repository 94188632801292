import { Grid } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { PromoCustomerSegment } from 'components/_dashboard/promotion/components/promoCustomerSegment';
import { getBaseInfoValidationList } from 'components/_dashboard/promotion/tools';
import { CommonInput } from 'components/_dashboard/promotion/formik/commonInput';
import { renderContent, tabContent } from '../tabContent';
import { IPromoProps } from '../../type';

export const VoucherBaseInfo: React.FC<IPromoProps> = (props) => {
  const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;

  const canMove = useCallback(async () => {
    // name is required
    // numberOfDeals is required and greater than 0
    let message = '';
    let canMove = true;
    try {
      const list = getBaseInfoValidationList();
      for (const path of list) {
        await setFieldTouchedNameFunc(path);
      }
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [setFieldTouchedNameFunc]);

  const wrappedCanMove = useCallback(() => canMove(), [canMove]);
  useEffect(() => {
    setCanMoveFunc(() => wrappedCanMove);
  }, [setCanMoveFunc, wrappedCanMove]);

  const commonContents: tabContent[] = [
    {
      mdSize: 12,
      sxSize: 12,
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'name'}
          label="Name"
          placeholder="Please enter the name of the voucher/offer/lucky draw"
        />
      )
    },
    {
      mdSize: 12,
      sxSize: 12,
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'description'}
          label="Description"
          placeholder="Please enter the description of the voucher"
          multiline={true}
        />
      )
    },
    {
      node: (
        <CommonInput
          formik={formik}
          fieldPath={'numberOfDeals'}
          type="number"
          label="How many deals would you like to publish?"
          placeholder="Number of Deals"
        />
      )
    },
    {
      node: <></>
    },
    {
      node: <PromoCustomerSegment formik={formik} />
    }
  ];

  return (
    <Grid sx={{ width: '100%' }} container spacing={1}>
      {renderContent(commonContents)}
    </Grid>
  );
};
