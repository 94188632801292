import { gql } from '@apollo/client';

export const GET_LOYALTY_MEMBERS = gql`
  query loyaltyMembers {
    loyaltyMembers {
      loyaltyId
      userId
      userName
      userPhone
      userEmail
      merchantId
      loyaltyPoints
      spendProgress {
        currentSpend
        targetSpend
        promotionId
      }
      stampProgress {
        currentStamps
        targetStamps
        promotionId
      }
      redeemHistory {
        promotionId
        usedStamps
        usedPoints
        createdAt
        voucherId
      }
    }
  }
`;

export const GET_PROMOTIONS_ON_LOYALTY_MEMBERS = gql`
  query getPromotionsOnLoyaltyMembers {
    getPromotions {
      id
      name
      status
    }
  }
`;
