import { LoadingButton } from '@mui/lab';
import { TableCell, TableRow } from '@mui/material';
import { Promotion } from '@type/promotion';
import {
  createPromoFunc,
  hasOfferVoucherSettings,
  updateEffectiveDateTime
} from 'components/_dashboard/promotion/tools';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fDateTimeSecond } from 'utils/formatTime';

interface PromotionHistoryTableRowProps {
  promotion: Promotion;
}

export const PromotionHistoryTableRow: React.FC<PromotionHistoryTableRowProps> = (props) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbarHelper();
  const { promotion } = props;
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
  const { name, type, description, createdAt } = promotion;

  const onDuplicatePromotion = async (promotion: Promotion) => {
    if (isDuplicateLoading) return;
    setIsDuplicateLoading(true);
    try {
      const effectiveDateTime = updateEffectiveDateTime(promotion.effectiveDateTime);
      const newPromo = { ...promotion };
      if (newPromo) {
        newPromo.effectiveDateTime = effectiveDateTime;
        // if newPromo contains offerVoucherSettings, update effectiveDateTime
        if (hasOfferVoucherSettings(newPromo)) {
          newPromo.offerVoucherSettings.effectiveDateTime = effectiveDateTime;
        }
      }
      const isSuccess = await createPromoFunc(newPromo, promotion.type, false);
      if (isSuccess) {
        showSnackbar(t('Duplicate Success'), 'success');
      }
    } catch (error) {
      if (error instanceof Error) {
        console.log('Error message:', error.message);
        console.log('Stack trace:', error.stack);
      } else {
        console.log('Unknown error:', error);
      }
    } finally {
      setIsDuplicateLoading(false);
    }
  };

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell align="left">{name}</TableCell>
      <TableCell align="left">{description}</TableCell>
      <TableCell align="left">{createdAt && fDateTimeSecond(createdAt / 1000)}</TableCell>
      <TableCell align="left">{type}</TableCell>
      <TableCell align="left">
        <LoadingButton
          size="small"
          variant="outlined"
          loading={isDuplicateLoading}
          onClick={() => onDuplicatePromotion(promotion)}
        >
          {t('Duplicate')}
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
};
