import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import { createBuyNGetNFreePromotionVariables } from '__generated__/createBuyNGetNFreePromotion';
import { createLoyaltyPromotionVariables } from '__generated__/createLoyaltyPromotion';
import { createLuckyDrawPromotionVariables } from '__generated__/createLuckyDrawPromotion';
import { createPercentagePromotionVariables } from '__generated__/createPercentagePromotion';
import { createSpendPromotionVariables } from '__generated__/createSpendPromotion';
import { deletePromotionVariables } from '__generated__/deletePromotion';
import { getBuyNGetNFreePromotionVariables } from '__generated__/getBuyNGetNFreePromotion';
import { getLoyaltyPromotionVariables } from '__generated__/getLoyaltyPromotion';
import { getLuckyDrawPromotionVariables } from '__generated__/getLuckyDrawPromotion';
import { getPercentagePromotionVariables } from '__generated__/getPercentagePromotion';
import { getSpendPromotionVariables } from '__generated__/getSpendPromotion';
import { publishPromotionVariables } from '__generated__/publishPromotion';
import { unpublishPromotionVariables } from '__generated__/unpublishPromotion';
import { updateBuyNGetNFreePromotionVariables } from '__generated__/updateBuyNGetNFreePromotion';
import { updateLoyaltyPromotionVariables } from '__generated__/updateLoyaltyPromotion';
import { updateLuckyDrawPromotionVariables } from '__generated__/updateLuckyDrawPromotion';
import { updatePercentagePromotionVariables } from '__generated__/updatePercentagePromotion';
import {
  CREATE_BUY_GET_FREE,
  CREATE_LOYALTY_PROMOTION,
  CREATE_LUCKY_DRAW,
  CREATE_PERCENTAGE_PROMOTION,
  CREATE_SPEND_PROMOTION,
  DELETE_PROMOTION,
  GET_ALL_PROMOTIONS,
  GET_BUY_GET_FREE_PROMOTION,
  GET_LOYALTY_PROMOTION,
  GET_LUCKY_DRAW,
  GET_PERCENTAGE_PROMOTION,
  GET_PROMOTIONS_ON_LUCKY_DRAW,
  GET_SPEND_PROMOTION,
  PUBLISH_PROMOTION,
  UNPUBLISH_PROMOTION,
  UPDATE_BUY_GET_FREE_PROMOTION,
  UPDATE_LOYALTY_PROMOTION,
  UPDATE_LUCKY_DRAW,
  UPDATE_PERCENTAGE_PROMOTION,
  UPDATE_SPEND_PROMOTION
} from '_apis_/queries/promotion';
import { getValidPromotions, removeTypenameKey } from 'components/_dashboard/promotion/tools';
import { client } from 'index';
import { Promotion } from '../../@type/promotion';
// ----------------------------------------------------------------------

type PromotionState = {
  isLoading: boolean;
  error: boolean;
  promotionList: Promotion[];
  validPromotions: Promotion[];
};

const initialState: PromotionState = {
  isLoading: false,
  error: false,
  promotionList: [],
  validPromotions: []
};

const slice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: any) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state: any, action: any) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setPromotionList(state: any, action: any) {
      if (action.payload) {
        state.promotionList = action.payload;
      } else {
        state.promotionList = [];
      }
    },
    // GET PROMOTIONS
    getPromotionsSuccess(state: any, action: any) {
      state.isLoading = false;
      state.promotionList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromotionList.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(fetchPromotionList.fulfilled, (state, action) => {
        const promotions = action.payload;
        const validPromotions = getValidPromotions(promotions);
        state.promotionList = promotions;
        state.validPromotions = validPromotions;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(fetchPromotionList.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(fetchAllPromotionList.pending, (state) => {
        state.isLoading = true;
        state.error = false;
      })
      .addCase(fetchAllPromotionList.fulfilled, (state, action) => {
        const promotions = action.payload;
        const validPromotions = getValidPromotions(promotions);
        state.promotionList = promotions;
        state.validPromotions = validPromotions;
        state.isLoading = false;
        state.error = false;
      })
      .addCase(fetchAllPromotionList.rejected, (state) => {
        state.isLoading = false;
        state.error = true;
      })
      .addCase(publishPromotion.fulfilled, (state, action) => {
        const result = action.payload;
        if (result) {
          // update promotion list
          state.promotionList = state.promotionList.map((promotion) => {
            if (promotion.id === action.meta.arg.promotionId) {
              promotion.status = 'published';
            }
            return promotion;
          });
          state.validPromotions = getValidPromotions(state.promotionList);
        }
      })
      .addCase(unPublishPromotion.fulfilled, (state, action) => {
        const result = action.payload;
        if (result) {
          // update promotion list
          state.promotionList = state.promotionList.map((promotion) => {
            if (promotion.id === action.meta.arg.promotionId) {
              console.log('promotion', promotion);
              promotion.status = 'draft';
            }
            return promotion;
          });
          state.validPromotions = getValidPromotions(state.promotionList);
        }
      })
      .addCase(deletePromotion.fulfilled, (state, action) => {
        const result = action.payload;
        if (result) {
          state.promotionList = state.promotionList.filter(
            (promotion) => promotion.id !== action.meta.arg.promotionId
          );
          state.validPromotions = getValidPromotions(state.promotionList);
        }
      });
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export const fetchAllPromotionList = createAsyncThunk(
  'promotion/fetchAllPromotionList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query<{ getPromotions: Promotion[] }>({
        query: GET_ALL_PROMOTIONS,
        fetchPolicy: 'network-only'
      });
      if (!response.data) rejectWithValue('No data returned');
      const data = response.data.getPromotions;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchPromotionList = createAsyncThunk(
  'promotion/fetchPromotionList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query<{ getPromotions: Promotion[] }>({
        query: GET_PROMOTIONS_ON_LUCKY_DRAW,
        fetchPolicy: 'network-only'
      });
      if (!response.data) rejectWithValue('No data returned');
      const data = response.data.getPromotions;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// createPercentagePromotion(
//   promotion: PercentagePromotionInput!
//   ): Boolean!
export const createPercentagePromoMutation = createAsyncThunk<
  boolean,
  createPercentagePromotionVariables,
  { rejectValue: string }
>('promotion/createPercentagePromoMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ createPercentagePromotion: boolean }>({
      mutation: CREATE_PERCENTAGE_PROMOTION,
      variables: input
    });
    if (response.data) {
      return response.data.createPercentagePromotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// createSpendPromotion(
// promotion: SpendPromotionInput!
// ): Boolean!
export const createSpendPromoMutation = createAsyncThunk<
  boolean,
  createSpendPromotionVariables,
  { rejectValue: string }
>('promotion/createSpendPromoMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ createSpendPromotion: boolean }>({
      mutation: CREATE_SPEND_PROMOTION,
      variables: input
    });
    if (response.data) {
      return response.data.createSpendPromotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// createBuyNGetNFreePromotion(
//   promotion: BuyNGetNFreePromotionInput!
//   ): Boolean!
export const createBuyNGetNFreePromoMutation = createAsyncThunk<
  boolean,
  createBuyNGetNFreePromotionVariables,
  { rejectValue: string }
>('promotion/createBuyNGetNFreePromoMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{
      createBuyNGetNFreePromotion: boolean;
    }>({
      mutation: CREATE_BUY_GET_FREE,
      variables: input
    });
    if (response.data) {
      return response.data.createBuyNGetNFreePromotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// createLuckyDrawPromotion(
//   promotion: LuckyDrawPromotionInput!
//   ): Boolean!
export const createLuckyDrawPromoMutation = createAsyncThunk<
  boolean,
  createLuckyDrawPromotionVariables,
  { rejectValue: string }
>('promotion/createLuckyDrawPromoMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ createLuckyDrawPromotion: boolean }>({
      mutation: CREATE_LUCKY_DRAW,
      variables: input
    });
    if (response.data) {
      return response.data.createLuckyDrawPromotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// createLoyaltyPromotion(
//   promotion: LoyaltyPromotionInput!
//   ): Boolean!
export const createLoyaltyPromoMutation = createAsyncThunk<
  boolean,
  createLoyaltyPromotionVariables,
  { rejectValue: string }
>('promotion/createLoyaltyPromoMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ createLoyaltyPromotion: boolean }>({
      mutation: CREATE_LOYALTY_PROMOTION,
      variables: input
    });
    if (response.data) {
      return response.data.createLoyaltyPromotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// updatePercentagePromotion(
//   promotion: PercentagePromotionInput!
//   promotionId: String!
//   ): Boolean!
export const updatePercentagePromoMutation = createAsyncThunk<
  boolean,
  updatePercentagePromotionVariables,
  { rejectValue: string }
>('promotion/updatePercentagePromoMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ updatePercentagePromotion: boolean }>({
      mutation: UPDATE_PERCENTAGE_PROMOTION,
      variables: input
    });
    if (response.data) {
      return response.data.updatePercentagePromotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// updateSpendPromotion(
//   promotion: SpendPromotionInput!
//   promotionId: String!
//   ): Boolean!
export const updateSpendPromoMutation = createAsyncThunk<
  boolean,
  updatePercentagePromotionVariables,
  { rejectValue: string }
>('promotion/updateSpendPromoMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ updateSpendPromotion: boolean }>({
      mutation: UPDATE_SPEND_PROMOTION,
      variables: input
    });
    if (response.data) {
      return response.data.updateSpendPromotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// updateBuyNGetNFreePromotion(
//   promotion: BuyNGetNFreePromotionInput!
//   promotionId: String!
//   ): Boolean!
export const updateBuyNGetNFreePromoMutation = createAsyncThunk<
  boolean,
  updateBuyNGetNFreePromotionVariables,
  { rejectValue: string }
>('promotion/updateBuyNGetNFreePromoMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{
      updateBuyNGetNFreePromotion: boolean;
    }>({
      mutation: UPDATE_BUY_GET_FREE_PROMOTION,
      variables: input
    });
    if (response.data) {
      return response.data.updateBuyNGetNFreePromotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// updateLuckyDrawPromotion(
//   promotion: LuckyDrawPromotionInput!
//   promotionId: String!
//   ): Boolean!
export const updateLuckyDrawPromoMutation = createAsyncThunk<
  boolean,
  updateLuckyDrawPromotionVariables,
  { rejectValue: string }
>('promotion/updateLuckyDrawPromoMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ updateLuckyDrawPromotion: boolean }>({
      mutation: UPDATE_LUCKY_DRAW,
      variables: input
    });
    if (response.data) {
      return response.data.updateLuckyDrawPromotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// updateLoyaltyPromotion(
//   promotion: LoyaltyPromotionInput!
//   promotionId: String!
//   ): Boolean!
export const updateLoyaltyPromoMutation = createAsyncThunk<
  boolean,
  updateLoyaltyPromotionVariables,
  { rejectValue: string }
>('promotion/updateLoyaltyPromoMutation', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ updateLoyaltyPromotion: boolean }>({
      mutation: UPDATE_LOYALTY_PROMOTION,
      variables: input
    });
    if (response.data) {
      return response.data.updateLoyaltyPromotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// getPercentagePromotion(
//   promotionId: String!
//   ): PercentagePromotion
export const getPercentagePromotionQuery = createAsyncThunk<
  Promotion,
  getPercentagePromotionVariables,
  { rejectValue: string }
>('promotion/getPercentagePromotionQuery', async (input, { rejectWithValue }) => {
  try {
    const response = await client.query<{ getPercentagePromotion: Promotion }>({
      query: GET_PERCENTAGE_PROMOTION,
      variables: input
    });
    if (response.data && response.data.getPercentagePromotion) {
      const promotion = { ...response.data.getPercentagePromotion };
      removeTypenameKey(promotion);
      return promotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// getSpendPromotion(
//   promotionId: String!
//   ): SpendPromotion
export const getSpendPromotionQuery = createAsyncThunk<
  Promotion,
  getSpendPromotionVariables,
  { rejectValue: string }
>('promotion/getSpendPromotionQuery', async (input, { rejectWithValue }) => {
  try {
    const response = await client.query<{ getSpendPromotion: Promotion }>({
      query: GET_SPEND_PROMOTION,
      variables: input
    });
    if (response.data && response.data.getSpendPromotion) {
      const promotion = { ...response.data.getSpendPromotion };
      removeTypenameKey(promotion);
      return promotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// getBuyNGetNFreePromotion(
//   promotionId: String!
//   ): BuyNGetNFreePromotion
export const getBuyNGetNFreePromotionQuery = createAsyncThunk<
  Promotion,
  getBuyNGetNFreePromotionVariables,
  { rejectValue: string }
>('promotion/getBuyNGetNFreePromotionQuery', async (input, { rejectWithValue }) => {
  try {
    const response = await client.query<{ getBuyNGetNFreePromotion: Promotion }>({
      query: GET_BUY_GET_FREE_PROMOTION,
      variables: input
    });
    if (response.data && response.data.getBuyNGetNFreePromotion) {
      const promotion = { ...response.data.getBuyNGetNFreePromotion };
      removeTypenameKey(promotion);
      return promotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// getLuckyDrawPromotion(
//   promotionId: String!
//   ): LuckyDrawPromotion
export const getLuckyDrawPromotionQuery = createAsyncThunk<
  Promotion,
  getLuckyDrawPromotionVariables,
  { rejectValue: string }
>('promotion/getLuckyDrawPromotionQuery', async (input, { rejectWithValue }) => {
  try {
    const response = await client.query<{ getLuckyDrawPromotion: Promotion }>({
      query: GET_LUCKY_DRAW,
      variables: input
    });
    if (response.data && response.data.getLuckyDrawPromotion) {
      const promotion = { ...response.data.getLuckyDrawPromotion };
      removeTypenameKey(promotion);
      return promotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// loyaltyPromotion(
//   promotionId: String!
//   ): LoyaltyPromotion
export const getLoyaltyPromotionQuery = createAsyncThunk<
  Promotion,
  getLoyaltyPromotionVariables,
  { rejectValue: string }
>('promotion/getLoyaltyPromotionQuery', async (input, { rejectWithValue }) => {
  try {
    const response = await client.query<{ loyaltyPromotion: Promotion }>({
      query: GET_LOYALTY_PROMOTION,
      variables: input
    });
    if (response.data && response.data.loyaltyPromotion) {
      // make a copy of the object
      const promotion = { ...response.data.loyaltyPromotion };
      removeTypenameKey(promotion);
      return promotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// publishPromotion(
//   promotionId: String!
//   ): Boolean!
export const publishPromotion = createAsyncThunk<
  boolean,
  publishPromotionVariables,
  { rejectValue: string }
>('promotion/publishPromotion', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ publishPromotion: boolean }>({
      mutation: PUBLISH_PROMOTION,
      variables: input
    });
    if (response.data) {
      return response.data.publishPromotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const unPublishPromotion = createAsyncThunk<
  boolean,
  unpublishPromotionVariables,
  { rejectValue: string }
>('promotion/unPublishPromotion', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ unpublishPromotion: boolean }>({
      mutation: UNPUBLISH_PROMOTION,
      variables: input
    });
    if (response.data) {
      return response.data.unpublishPromotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

// deletePromotion(
//   promotionId: String!
//   ): Boolean!
export const deletePromotion = createAsyncThunk<
  boolean,
  deletePromotionVariables,
  { rejectValue: string }
>('promotion/deletePromotion', async (input, { rejectWithValue }) => {
  try {
    const response = await client.mutate<{ deletePromotion: boolean }>({
      mutation: DELETE_PROMOTION,
      variables: input
    });
    if (response.data) {
      return response.data.deletePromotion;
    }
    if (response.errors) {
      return rejectWithValue(JSON.stringify(response.errors));
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const { setPromotionList } = slice.actions;
