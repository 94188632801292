import { Promotion } from '@type/promotion';
import { useCallback, useEffect, useState } from 'react';
import ItemButton, { ItemButtonColor } from './itemButton';
import { KeyValue, KeyValueList, Value } from './summaryType';
import {
  generateDateStrings,
  getOfferPromoName,
  getPromoListByIds,
  getVoucherPromoName
} from '../tools';
import { Grid, Typography } from '@mui/material';
import { PromotionType } from '__generated__/globalTypes';
import { RootState, useSelector } from 'redux/store';

export interface summaryProps {
  promotion: Promotion;
  voucherBackgroundColor?: string;
  offerBackgroundColor?: string;
}

export const PromoSummary = (props: summaryProps) => {
  const { promotion, voucherBackgroundColor, offerBackgroundColor } = props;
  const [offerItems, setOfferItems] = useState<KeyValue[]>([]);
  const [voucherItems, setVoucherItems] = useState<KeyValue[]>([]);
  const { promotionList } = useSelector((state: RootState) => state.promotion);
  const setOfferKeyValue = (key: string, value: Value) => {
    setOfferItems((prevItems) => {
      const exists = prevItems.some((item) => item.key === key);
      if (exists) {
        return prevItems.map((item) => (item.key === key ? { key, value } : item));
      } else {
        return [...prevItems, { key, value }];
      }
    });
  };
  const setVoucherKeyValue = (key: string, value: Value) => {
    setVoucherItems((prevItems) => {
      const exists = prevItems.some((item) => item.key === key);
      if (exists) {
        return prevItems.map((item) => (item.key === key ? { key, value } : item));
      } else {
        return [...prevItems, { key, value }];
      }
    });
  };

  const getVoucherName = (promo: Promotion) => {
    if (promo.isOffer) {
      return promo.offerVoucherSettings?.offerName ?? '-';
    }
    return promo.name || '-';
  };

  const getOfferName = (promo: Promotion) => {
    if (promo.isOffer) {
      return promo.name || '-';
    }
    return promo.offerVoucherSettings?.offerName ?? '-';
  };

  const getVoucherDescription = (promo: Promotion) => {
    if (promo.isOffer) {
      return promo.offerVoucherSettings?.offerDescription || '-';
    }
    return promo.description || '-';
  };
  const getOfferDescription = (promo: Promotion) => {
    if (promo.isOffer) {
      return promo.description || '-';
    }
    return promo.offerVoucherSettings?.offerDescription || '-';
  };

  const getNumberOfDeals = (promo: Promotion) => {
    return promo.numberOfDeals || '-';
  };
  const getCustomerSegment = (promo: Promotion) => {
    const isSignUpPromotion = promo.isSignUpPromotion;
    const element = isSignUpPromotion ? 'New customer sign up' : '-';
    return element;
  };
  const getIsHiddenInApp = (promo: Promotion) => {
    const isHiddenInApp = promo.isHiddenInApp;
    const element = isHiddenInApp ? 'True' : '-';
    return element;
  };
  const getMode = (promo: Promotion) => {
    const mode = promo.availableType
      ?.map((type) => {
        return type;
      })
      .join(', ');
    return mode || '-';
  };

  const getVoucherMinSpend = (promo: Promotion) => {
    if (!promo.isOffer) {
      const minimumAmount = promo.minimumAmount || 0;
      return minimumAmount === 0 ? 'No Minimum Spend' : `$${minimumAmount}`;
    }
    // this is offer
    const voucherMinimumAmount = promo.offerVoucherSettings?.minimumAmount || 0;
    return voucherMinimumAmount === 0 ? 'No Minimum Spend' : `$${voucherMinimumAmount}`;
  };

  const getOfferMinSpend = (promo: Promotion) => {
    const offerMinimumAmount = promo.minimumAmount || 0;
    return offerMinimumAmount === 0 ? 'No Minimum Spend' : `$${offerMinimumAmount}`;
  };

  const getApplyToOptions = (promo: Promotion) => {
    const applyToOptions = promo.applyOptionDiscount ? 'Yes' : 'No';
    return applyToOptions;
  };

  // voucher only
  const getPercentageAmountTags = (promo: Promotion) => {
    const includedGetItemsPromoTags = promo.includedGetItemsPromoTags || [];
    const excludedPromotionTags = promo.excludedPromotionTags || [];
    const includeTagsValue = (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        {includedGetItemsPromoTags.map((tag, index) => (
          <div key={index}>
            <ItemButton content={tag} />
          </div>
        ))}
        {includedGetItemsPromoTags.length === 0 && <ItemButton content={'All'} />}
      </div>
    );
    let excludeTagsValue = (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        {excludedPromotionTags.map((tag, index) => (
          <div key={index}>
            <ItemButton content={tag} />
          </div>
        ))}
      </div>
    );
    if (excludedPromotionTags.length === 0) {
      excludeTagsValue = <>-</>;
    }
    return { includeTagsValue, excludeTagsValue };
  };

  const getIncludedPromotionTags = (promo: Promotion) => {
    const includedPromotionTags = promo.includedPromotionTags || [];
    const excludedPromotionTags = promo.excludedPromotionTags || [];
    const tagsValue = (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        {includedPromotionTags.map((tag, index) => (
          <div key={index}>
            <ItemButton content={tag} />
          </div>
        ))}
        {includedPromotionTags.length === 0 && <ItemButton content={'All'} />}
        {excludedPromotionTags.map((tag, index) => (
          <div key={includedPromotionTags.length + index}>
            <ItemButton content={tag} color={ItemButtonColor.NEUTRALS} />
          </div>
        ))}
      </div>
    );
    return tagsValue;
  };

  // setVoucherKeyValue('Get', <ItemButton content={t('All')} />);
  const getIncludedGetTags = (promo: Promotion) => {
    if (promo.isOffer) {
      const includedGetItemsPromoTags = promo.includedGetItemsPromoTags || [];
      const offerExcludedItemTags = promo.offerVoucherSettings?.offerExcludedItemTags || [];
      const tagsValue = (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
          {includedGetItemsPromoTags.map((tag, index) => (
            <div key={tag + index}>
              <ItemButton content={tag} />
            </div>
          ))}
          {offerExcludedItemTags &&
            offerExcludedItemTags.map((tag, index) => (
              <div
                key={includedGetItemsPromoTags ? includedGetItemsPromoTags.length + index : index}
              >
                <ItemButton content={tag} color={ItemButtonColor.NEUTRALS} />
              </div>
            ))}
        </div>
      );
      return includedGetItemsPromoTags.length > 0 || offerExcludedItemTags.length > 0 ? (
        tagsValue
      ) : (
        <ItemButton content={'All'} />
      );
    }
    const includedGetItemsPromoTags = promo.includedGetItemsPromoTags || [];
    const tagsValue = (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        {includedGetItemsPromoTags.map((tag, index) => (
          <div key={tag + index}>
            <ItemButton content={tag} />
          </div>
        ))}
        {includedGetItemsPromoTags.length === 0 && <ItemButton content={'All'} />}
      </div>
    );
    return tagsValue;
  };

  // setOfferKeyValue('Spend', tagsValue);
  const getOfferSpendTags = (promo: Promotion) => {
    const includedPromotionTags = promo.includedPromotionTags || [];
    const excludedPromotionTags = promo.excludedPromotionTags || [];
    const tagsValue = (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        {includedPromotionTags.map((tag, index) => (
          <div key={index}>
            <ItemButton content={tag} color={ItemButtonColor.OFFER} />
          </div>
        ))}
        {includedPromotionTags.length === 0 && (
          <ItemButton content={'All'} color={ItemButtonColor.OFFER} />
        )}
        {excludedPromotionTags.map((tag, index) => (
          <div key={includedPromotionTags.length + index}>
            <ItemButton content={tag} color={ItemButtonColor.NEUTRALS} />
          </div>
        ))}
      </div>
    );
    return tagsValue;
  };

  const getUpToAmount = (promo: Promotion) => {
    if (!promo.isOffer) {
      const voucherUpToAmount = promo.offerVoucherSettings?.upToAmount || 0;
      if (voucherUpToAmount === 0) {
        return 'No Limit';
      }
      return `$${voucherUpToAmount}`;
    }
    const upToAmount = promo.upToAmount || 0;
    if (upToAmount === 0) {
      return 'No Limit';
    }
    return `$${upToAmount}`;
  };

  const getVoucherEffectiveDateTime = (promotion: Promotion) => {
    let effectiveDateTime = promotion.effectiveDateTime;
    if (promotion.isOffer) {
      effectiveDateTime = promotion.offerVoucherSettings?.effectiveDateTime || [];
    }
    const formattedDateStrings = generateDateStrings(effectiveDateTime);
    const element = (
      <div>
        {formattedDateStrings.map((dateString, index) => (
          <div key={index}>{dateString}</div>
        ))}
      </div>
    );
    return element;
  };

  const getVoucherTitle = (promo: Promotion) => {
    if (promo.isOffer) {
      return 'Voucher for next Visit';
    }
    return '';
  };

  const getOfferEffectiveDateTime = (promotion: Promotion) => {
    const effectiveOfferDateTime = promotion.effectiveDateTime;
    const formattedOfferDateStrings = generateDateStrings(effectiveOfferDateTime);
    const offerElement = (
      <div>
        {formattedOfferDateStrings.map((dateString, index) => (
          <div key={index}>{dateString}</div>
        ))}
      </div>
    );
    return offerElement;
  };

  const getVoucherKeyName = (promo: Promotion) => {
    if (promo.isOffer) {
      return 'Voucher Name';
    } else {
      return 'Name';
    }
  };

  // resultingPromotions: [ID!]!
  const getPrizeDescription = (promo: Promotion) => {
    return promo.prizeDescription || '-';
  };
  const getLoyaltyPointsPerDollar = (promo: Promotion) => {
    return promo.loyaltySettings.pointsPerDollar || '-';
  };
  const getLoyaltyMaxPointsPerOrder = (promo: Promotion) => {
    return promo.loyaltySettings.maxPointsPerTransaction || '-';
  };
  const getLoyaltyExpiryDays = (promo: Promotion) => {
    return promo.loyaltySettings.expiryDays || '-';
  };
  const getWinnersCount = (promo: Promotion) => {
    return promo.winnerCount || '-';
  };
  const getEntryLimitPerUser = (promo: Promotion) => {
    return promo.entryLimitPerUser || '-';
  };
  const getWinCountLimitPerPerson = (promo: Promotion) => {
    return promo.winCountLimitPerPerson || '-';
  };
  const getIsEntryAutomatic = (promo: Promotion) => {
    return promo.isEntryAutomatic ? 'Yes' : 'No';
  };
  const getIsPaymentRequired = (promo: Promotion) => {
    return promo.isPaymentRequired ? 'Yes' : 'No';
  };
  const getIsLoyalty = (promo: Promotion) => {
    return promo.isLoyalty ? 'Yes' : 'No';
  };
  const getResultingPromotions = (promotionList: Promotion[], promo: Promotion) => {
    const promoIds = promo.resultingPromotions || [];
    const nameList = getPromoListByIds(promotionList, promoIds);
    const element = (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        {nameList.map((promo, index) => (
          <div key={index}>
            <ItemButton content={promo.name} />
          </div>
        ))}
      </div>
    );
    return element;
  };

  const getLoyaltyBenefitPromoTags = (promotionList: Promotion[], promo: Promotion) => {
    const loyaltyBenefits = promo.loyaltySettings.loyaltyBenefits || [];
    const promoIds = [];
    for (const loyaltyBenefit of loyaltyBenefits) {
      // append loyaltyBenefit.benefitPromotionIds to promoIds
      promoIds.push(...loyaltyBenefit.benefitPromotionIds);
    }
    const nameList = getPromoListByIds(promotionList, promoIds);
    const element = (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
        {nameList.map((promo, index) => (
          <div key={index}>
            <ItemButton content={promo.name} />
          </div>
        ))}
      </div>
    );
    return element;
  };

  const build = useCallback(() => {
    if (!promotion) return;
    setVoucherKeyValue(getVoucherKeyName(promotion), getVoucherName(promotion));
    setVoucherKeyValue('Description', getVoucherDescription(promotion));
    setVoucherKeyValue('Number of Deals', getNumberOfDeals(promotion));
    setVoucherKeyValue('Promotion', getVoucherPromoName(promotion));
    if (!promotion.isOffer) {
      setVoucherKeyValue('Customer Segment', getCustomerSegment(promotion));
      setVoucherKeyValue('Hidden In App', getIsHiddenInApp(promotion));
    }
    setVoucherKeyValue('Mode', getMode(promotion));
    setVoucherKeyValue('Minimum Spend', getVoucherMinSpend(promotion));
    if (promotion.type !== PromotionType.luckyDraw) {
      setVoucherKeyValue('Apply to Options', getApplyToOptions(promotion));
      const includedTagsValue = getIncludedPromotionTags(promotion);
      const IncludedGetItemsPromoTags = getIncludedGetTags(promotion);
      const { includeTagsValue, excludeTagsValue } = getPercentageAmountTags(promotion);
      if (promotion.type === PromotionType.percentage) {
        setVoucherKeyValue('Included', includeTagsValue);
        setVoucherKeyValue('Excluded', excludeTagsValue);
      }
      if (promotion.type === PromotionType.buynGetFree) {
        setVoucherKeyValue('Buy', includedTagsValue);
      }
      if (promotion.type === PromotionType.spend) {
        setVoucherKeyValue('Spend on', includedTagsValue);
      }
      setVoucherKeyValue('Get', IncludedGetItemsPromoTags);
      setVoucherKeyValue('Up to Amount', getUpToAmount(promotion));
    }
    if (promotion.isLoyalty) {
      setVoucherKeyValue('Is Loyalty', getIsLoyalty(promotion));
    }
    if (promotion.type === PromotionType.luckyDraw) {
      setVoucherKeyValue('Prize Description', getPrizeDescription(promotion));
      setVoucherKeyValue('Entry Limit Per Customer', getEntryLimitPerUser(promotion));
      setVoucherKeyValue('Win Count Limit Per Customer', getWinCountLimitPerPerson(promotion));
      setVoucherKeyValue('Global Winner Count', getWinnersCount(promotion));
      setVoucherKeyValue('Auto Entry', getIsEntryAutomatic(promotion));
      setVoucherKeyValue('Payment Required', getIsPaymentRequired(promotion));
      setVoucherKeyValue('Rewards', getResultingPromotions(promotionList, promotion));
    }
    if (promotion.type === PromotionType.loyalty) {
      setVoucherKeyValue('Points Per Dollar', getLoyaltyPointsPerDollar(promotion));
      setVoucherKeyValue('Max Points Per Order', getLoyaltyMaxPointsPerOrder(promotion));
      setVoucherKeyValue('Expiry Days', getLoyaltyExpiryDays(promotion));
      setVoucherKeyValue('Rewards', getLoyaltyBenefitPromoTags(promotionList, promotion));
    }
    setVoucherKeyValue('Effective Date', getVoucherEffectiveDateTime(promotion));
    if (promotion.isOffer) {
      setOfferKeyValue('Offer Name', getOfferName(promotion));
      setOfferKeyValue('Description', getOfferDescription(promotion));
      setOfferKeyValue('Number of Deals', getNumberOfDeals(promotion));
      setOfferKeyValue('Customer Segment', getCustomerSegment(promotion));
      setOfferKeyValue('Promotion', getOfferPromoName(promotion));
      setOfferKeyValue('Mode', getMode(promotion));
      setOfferKeyValue('Minimum Spend', getOfferMinSpend(promotion));
      setOfferKeyValue('Spend', getOfferSpendTags(promotion));
      setOfferKeyValue('Effective Date', getOfferEffectiveDateTime(promotion));
    }
  }, [promotionList, promotion]);

  useEffect(() => {
    build();
  }, [build, promotion]);

  return (
    <Grid container direction="column" spacing={4}>
      {promotion.isOffer && (
        <Grid item>
          <KeyValueList items={offerItems} backgroundColor={offerBackgroundColor} />
        </Grid>
      )}
      <Grid item>
        <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold' }}>
          {getVoucherTitle(promotion)}
        </Typography>
      </Grid>
      <Grid item>
        <KeyValueList items={voucherItems} backgroundColor={voucherBackgroundColor} />
      </Grid>
    </Grid>
  );
};
