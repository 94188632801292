import React from 'react';
import { Stack } from '@mui/material';

import { DateTimeListProvider, EffectiveDateTimeType } from './type/type';
import { useEffectiveDateTime } from './useEffectiveDateTime';

interface EffectiveDateTImeProps {
  dateTimesList: EffectiveDateTimeType[];
  setDateTimeList: (dateTimeList: EffectiveDateTimeType[]) => void;
  isOffer?: boolean;
}

const DateTimeItem: React.FC<{ item: EffectiveDateTimeType; index: number }> = ({
  item,
  index
}) => {
  const { DateTime } = useEffectiveDateTime({ effectiveDateTime: item, index: index });
  return <div>{DateTime}</div>;
};

const MultipleEffectiveDateTIme: React.FC<EffectiveDateTImeProps> = (props) => {
  const { dateTimesList, setDateTimeList, isOffer = false } = props;

  return (
    <div>
      <DateTimeListProvider
        initialDateTimeList={dateTimesList}
        externalSetDateTimeList={setDateTimeList}
        isOffer={isOffer}
      >
        <Stack spacing={{ xs: 3 }}>
          {dateTimesList.map((item: EffectiveDateTimeType, index: number) => (
            <DateTimeItem key={index} item={item} index={index} />
          ))}
        </Stack>
      </DateTimeListProvider>
    </div>
  );
};

export const useMultipleEffectiveDateTime = (props: EffectiveDateTImeProps) => {
  const { dateTimesList, setDateTimeList, isOffer = false } = props;
  const effectiveDateTime = (
    <MultipleEffectiveDateTIme
      dateTimesList={dateTimesList}
      setDateTimeList={setDateTimeList}
      isOffer={isOffer}
    />
  );
  return { effectiveDateTime };
};
